<template>
  <v-container fluid class="landing-container">
    <v-row class="mb-1">
      <v-col :md="2" cols="5">
        <LogoText />
      </v-col>
    </v-row>

    <v-divider></v-divider>

    <v-row class="mt-1">
      <v-btn text class="text-lowercase" @click="toAnchor('about')">
        About tealeaf
      </v-btn>
      <v-btn text class="text-lowercase" @click="toAnchor('performance')">
        Current Performance
      </v-btn>
      <v-btn text class="text-lowercase" @click="toAnchor('getstarted')">
        Get Started</v-btn
      >
    </v-row>

    <v-row class="mt-3">
      <v-col>
        <span class="tagline">
          The World's First Commercially Available AI Stock Predictor
        </span>
      </v-col>
    </v-row>

    <v-row class="my-10">
      <!-- spacer row -->
      <div class="my-10"></div>
    </v-row>

    <v-row id="about" class="mt-3 mx-8">
      <v-col :md="6" cols="12">
        <div><strong>About tealeaf</strong></div>
      </v-col>
      <v-col :md="6" cols="12">
        <p>Take back power from Wall Street with tealeaf</p>
        <p>
          Our custom made Deep Learning pipeline produces new insights around
          the clock. Data flow starts in grassroots conversations on social
          media. These conversations are then vectorized in our modified RoBERTa
          language model. Language vectors then get processed through our neural
          net inferencing system to produce predictions on dozens of the hottest
          stocks.
        </p>
      </v-col>
    </v-row>

    <v-row class="mb-5">
      <v-col :md="4" offset-md="3" cols="12"
        ><chart-123 style="display: block"
      /></v-col>
    </v-row>

    <v-row id="performance" class="mt-3 mx-8">
      <v-col :md="6" cols="12">
        <div><strong>Current performance</strong></div>
      </v-col>
      <v-col :md="6" cols="12">
        <p>We put our predictions to work</p>
        <p>
          Using a simple buy/sell algorithmic trading process we are able to
          continuously test tealeaf's predictions. Below is a live graph showing
          our performance over the last 30 days.
        </p>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <MonthGraph />
      </v-col>
    </v-row>

    <v-row class="my-10">
      <!-- spacer row -->
    </v-row>

    <v-row id="getstarted" class="mt-3 mx-8">
      <v-col :md="6" cols="12">
        <div><strong>Get Started</strong></div>
      </v-col>
      <v-col :md="6" cols="12">
        <p>Join us today and get your first 24 hours free</p>
        <p>Sign up now or take a look at our free stocks of the week</p>
        <div>
          <v-btn outlined class="mr-2 my-2" @click="$router.push('/signup')">
            Sign Up
          </v-btn>
          <v-btn outlined class="mr-2" @click="$router.push('/stocks')">
            Stocks of the week
          </v-btn>
        </div>
      </v-col>
    </v-row>

    <v-row class="my-10">
      <!-- spacer row -->
      <div class="my-10"></div>
    </v-row>

    <v-footer class="landing-footer ma-n3" padless>
      <v-row justify="center" no-gutters>
        <v-btn
          text
          small
          rounded
          class="my-2"
          @click="$router.push('/contact')"
        >
          Contact us
        </v-btn>
        <v-btn text small rounded class="my-2" @click="$router.push('/signup')">
          Sign Up
        </v-btn>
        <v-btn text small rounded class="my-2" @click="$router.push('/login')">
          Log In
        </v-btn>
        <v-col class="text-caption text-center text--secondary mb-2" cols="12">
          {{ new Date().getFullYear() }} — <strong>tealeaf</strong>
        </v-col>
      </v-row>
    </v-footer>
  </v-container>
</template>

<script>
import LogoText from "../assets/LogoText.vue";
import Chart123 from "../assets/Chart123.vue";
import MonthGraph from "../components/MonthGraph.vue";
export default {
  name: "Landing",
  components: {
    LogoText,
    Chart123,
    MonthGraph,
  },
  beforeCreate() {
    if (this.$cookies.isKey('token')) {
      this.$router.push("/stocks");
    }
  },
  methods: {
    toAnchor(name) {
      document.getElementById(name).scrollIntoView({ behavior: "smooth" });
    },
  },
};
</script>

<style scoped>
.landing-container {
  background-color: #eaf2ef;
}

.landing-footer {
  background-color: #eaf2ef;
}

@import url("https://fonts.googleapis.com/css2?family=Montserrat&display=swap");

.tagline {
  font-family: "Montserrat", sans-serif;
  font-size: 36px;
}

@media screen and (min-width: 600px) {
  .tagline {
    font-family: "Montserrat", sans-serif;
    font-size: 90px;
  }
}
</style>
<template>
  <line-chart v-if="loaded" :chartdata="chartdata" :options="options" />
</template>

<script>
import LineChart from "./LineChart.vue";
import DataService from "../services/DataService";
import moment from "moment-timezone";

export default {
  name: "MonthGraph",
  components: { LineChart },
  data: () => ({
    loaded: false,
    chartdata: null,
    options: {
      responsive: true,
      maintainAspectRatio: false,
      tooltips: {
        intersect: false,
        mode: "index",
        backgroundColor: "rgba(0, 0, 0, 0.4)",
      },
      elements: {
        point: {
          radius: 0,
        },
      },
      legend: {
        display: false,
      },
      scales: {
        xAxes: [
          {
            gridLines: {
              display: false,
            },
            afterTickToLabelConversion: function (data) {
              var xLabels = data.ticks;
              xLabels.forEach(function (labels, i) {
                if (i % 3 !== 0) {
                  xLabels[i] = "";
                }
              });
            },
          },
        ],
        yAxes: [
          {
            display: false,
            gridLines: {
              display: false,
            },
          },
        ],
      },
    },
  }),
  async mounted() {
    this.loaded = false;
    try {
      let response = await DataService.getMonthHistory("Sam");

      let readableTimes = response.timestamp.map((epoch) =>
        moment.unix(epoch).format("M/D/Y")
      );

      let tempData = {};
      tempData.labels = readableTimes;
      tempData.datasets = [
        {
          backgroundColor: "#22624C",
          data: response.equity,
        },
      ];
      tempData.pointStyle = "star";
      this.chartdata = tempData;
      this.loaded = true;
    } catch (e) {
      console.log(e);
    }
  },
};
</script>